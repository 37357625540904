@import './framework.css';
/* THIS IS A FREE VERSION OF STARTUP! */
/* 
	Table of contents:
	1. Common styles
	2. Common responsive styles
	3. Headers
	4. Navigation
	5. Contents
	6. Testimonials
	7. Features
	8. Forms
	9. Call to Actions
	10. Teams
	11. Pricing Tables
	12. Contacts
	13. Showcases
	14. Footers
	15. Ecommerce
	16. Applications
*/
/* 1. Common styles */
body {
  font-family: DM Sans, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #1e0e62;
  -webkit-font-smoothing: antialiased;
}
body.dev {
  background: #ccc;
  min-height: 10000px;
}

nav,
header,
section,
footer {
  position: relative;
}

header,
section,
[data-bg-src],
[data-bg-src-2x] {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

header,
section {
  overflow: hidden;
}

header nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

nav {
  width: 100%;
}
nav.transparent {
  background: transparent;
}

a,
button {
  cursor: pointer;
  transition: all 0.25s;
}
a:hover,
button:hover {
  text-decoration: none;
}
a:active,
a:focus,
button:active,
button:focus {
  text-decoration: none;
  outline: none;
}

button {
  border: none;
  outline: none !important;
}

input,
textarea {
  border: 0;
  outline: none;
  background: transparent;
  color: #1e0e62;
  resize: none;
}

label {
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  margin: 0;
  font-weight: 700;
  font-family: DM Sans, Helvetica, Arial, sans-serif;
}

h1,
h2 {
  font-size: 58px;
  line-height: 70px;
}
h1.big,
h2.big {
  font-size: 72px;
  line-height: 86px;
}
h1.small,
h2.small {
  font-size: 42px;
  line-height: 52px;
  font-weight: 700;
}

h3 {
  font-size: 42px;
  line-height: 52px;
}

b,
strong {
  font-weight: 700;
}

.alert {
  display: none;
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 970px;
  z-index: 2000;
}
.alert-dismissable .close,
.alert-dismissible .close {
  top: 0;
}

/* Change AOS plugin default settings */
[data-aos='fade-down'] {
  transform: translate3d(0, -35px, 0);
}

[data-aos='fade-up'] {
  transform: translate3d(0, 35px, 0);
}

.container {
  position: relative;
}

::-moz-selection {
  background: #e93a7d;
  color: #ffffff;
}

::-webkit-selection {
  background: #e93a7d;
  color: #ffffff;
}

::selection {
  background: #e93a7d;
  color: #ffffff;
}

/* 2. Common responsive styles */
/* Smaller than 1200px */
@media (max-width: 1199.98px) {
  header[class^='pt-'],
  header[class*=' pt-'],
  section[class^='pt-'],
  section[class*=' pt-'] {
    padding-top: 80px;
  }
  header[class^='pb-'],
  header[class*=' pb-'],
  section[class^='pb-'],
  section[class*=' pb-'] {
    padding-bottom: 80px;
  }

  h1 br,
  h2 br,
  h3 br,
  .text-adaptive br {
    display: none;
  }

  h1,
  h2 {
    font-size: 52px;
    line-height: 62px;
  }
  h1.big,
  h2.big {
    font-size: 62px;
    line-height: 72px;
  }
  h1.small,
  h2.small {
    font-size: 36px;
    line-height: 46px;
  }

  h3 {
    font-size: 36px;
    line-height: 46px;
  }

  .navigation_mobile {
    display: block;
  }
}
/* Smaller than 992px */
@media (max-width: 991.98px) {
  h1,
  h2 {
    font-size: 42px;
    line-height: 52px;
  }
  h1.big,
  h2.big {
    font-size: 52px;
    line-height: 62px;
  }
  h1.small,
  h2.small {
    font-size: 32px;
    line-height: 42px;
  }

  h3 {
    font-size: 32px;
    line-height: 42px;
  }

  .text-adaptive.f-24 {
    font-size: 22px;
    line-height: 32px;
  }
  .text-adaptive.f-22 {
    font-size: 20px;
    line-height: 30px;
  }
  .text-adaptive.f-20 {
    font-size: 18px;
    line-height: 28px;
  }

  .alert {
    top: 10px;
    left: 20px;
    right: 20px;
    transform: none;
    width: auto;
    padding-left: 40px;
  }
}
/* Smaller than 768px */
@media (max-width: 767.98px) {
  h1,
  h2 {
    font-size: 32px;
    line-height: 42px;
  }
  h1.big,
  h2.big {
    font-size: 42px;
    line-height: 52px;
  }
  h1.small,
  h2.small {
    font-size: 26px;
    line-height: 36px;
  }

  h3 {
    font-size: 26px;
    line-height: 36px;
  }

  .text-adaptive.f-24 {
    font-size: 20px;
    line-height: 30px;
  }
  .text-adaptive.f-22 {
    font-size: 18px;
    line-height: 28px;
  }
  .text-adaptive.f-20 {
    font-size: 16px;
    line-height: 26px;
  }
  .text-adaptive.f-18 {
    font-size: 16px;
    line-height: 26px;
  }

  .btn.lg {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    font-size: 18px;
    font-weight: 500;
  }
  .btn.lg[class^='border-'],
  .btn.lg[class*=' border-'] {
    line-height: 46px;
  }
  .btn.xl {
    height: 60px;
    line-height: 60px;
    padding: 0 35px;
    font-size: 18px;
    font-weight: 500;
  }
  .btn.xl[class^='border-'],
  .btn.xl[class*=' border-'] {
    line-height: 56px;
  }

  .input.lg {
    height: 50px;
    font-size: 18px;
    font-weight: 500;
  }
}
/* Smaller than 576px */
@media (max-width: 575.98px) {
  h1,
  h2 {
    font-size: 26px;
    line-height: 36px;
  }
  h1.big,
  h2.big {
    font-size: 32px;
    line-height: 42px;
  }
  h1.small,
  h2.small {
    font-size: 22px;
    line-height: 32px;
  }

  .btn.xl {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
  }
  .btn.xl[class^='border-'],
  .btn.xl[class*=' border-'] {
    line-height: 46px;
  }
}
/* 3. Headers */
@media (max-width: 767.98px) {
  .header_1 .text-adaptive {
    margin-top: 20px;
  }
  .header_1 .buttons {
    margin-top: 40px;
  }
}

@media (max-width: 767.98px) {
  .header_2 .text-adaptive {
    margin-top: 20px;
  }
  .header_2 form {
    margin-top: 40px;
  }
  .header_2 form .input {
    text-align: center;
    max-width: 100%;
    width: 100%;
  }
  .header_2 form .btn {
    margin-top: 15px;
  }
}

/* 4. Navigations */
@media (max-width: 1199.98px) {
  .navigation_2 a {
    margin: 0 15px;
  }
  .navigation_2 .dropdown a {
    margin: 0;
  }
}

/* 5. Contents */
@media (max-width: 767.98px) {
  .content_1 h2 {
    margin-top: 10px;
  }
}

@media (max-width: 991.98px) {
  .content_2 .text-adaptive {
    margin-top: 15px;
  }
}

/* 6. Testimonials */
@media (max-width: 767.98px) {
  .testimonial_2 .text-adaptive {
    margin-top: 10px;
    margin-bottom: 25px;
  }
}

/* 7. Features */
@media (max-width: 1199.98px) {
  .feature_1[class^='pb-'],
  .feature_1[class*=' pb-'] {
    padding-bottom: 30px;
  }
}
@media (max-width: 767.98px) {
  .feature_1 .title {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .feature_1 .title br {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .feature_2[class^='pb-'],
  .feature_2[class*=' pb-'] {
    padding-bottom: 30px;
  }
  .feature_2 .description {
    margin-top: 20px;
  }
}

/* 8. Forms */
@media (max-width: 467.98px) {
  .form_1 h2 {
    margin-bottom: 20px;
  }
  .form_1 form {
    padding: 30px;
  }
}
@media (max-width: 359.98px) {
  .form_1 form {
    padding: 30px 20px;
  }
}

.form_2 .img_holder img {
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
@media (max-width: 1199.98px) {
  .form_2 form {
    padding: 80px 0;
  }
  .form_2 .buttons {
    margin-top: 10px;
  }
  .form_2 button {
    margin-right: 0px;
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .form_2 form {
    max-width: 290px;
    margin: 0 auto;
  }
}

/* 9. Call to Actions */
@media (max-width: 767.98px) {
  .call_to_action_1 .description {
    margin: 10px 0 30px 0;
  }
  .call_to_action_1 .btn {
    margin-bottom: 15px;
  }
}

.call_to_action_2 .inner {
  border: 2px solid #ebeaed;
}
@media (max-width: 991.98px) {
  .call_to_action_2 .inner {
    padding: 40px 30px;
  }
}
@media (max-width: 575.98px) {
  .call_to_action_2 .inner {
    padding: 30px 20px;
  }
}

/* 10. Teams */
.team_1 .block {
  border: 2px solid #ebeaed;
}
.team_1 .block_socials {
  border-top: 2px solid #ebeaed;
}
.team_1 .block_socials .border_right {
  border-right: 2px solid #ebeaed;
}

.team_2 .bordered {
  border-left: 2px solid #ebeaed;
  border-right: 2px solid #ebeaed;
}
@media (max-width: 767.98px) {
  .team_2 .bordered {
    border: none;
    border-top: 2px solid #ebeaed;
    border-bottom: 2px solid #ebeaed;
  }
}

/* 11. Pricing Tables */
.pricing_table_1 .block {
  border: 2px solid #ebeaed;
  border-bottom-left-radius: 10px;
}
.pricing_table_1 .block.noborder_right {
  border-right: none;
}
.pricing_table_1 .item {
  padding: 16px 0 14px 0;
  border-top: 2px solid #ebeaed;
}
.pricing_table_1 .price span.absolute {
  top: 5px;
  left: -15px;
}
@media (max-width: 991.98px) {
  .pricing_table_1[class^='pb-'],
  .pricing_table_1[class*=' pb-'] {
    padding-bottom: 30px;
  }
  .pricing_table_1 .block {
    margin: 0 auto 50px auto;
    max-width: 300px;
    border-radius: 10px;
  }
  .pricing_table_1 .block.noborder_right {
    border-right: 2px solid #ebeaed;
  }
}

/* 12. Contacts */
.contact_1 textarea.input {
  height: 170px;
}
.contact_1 .separate {
  position: relative;
}
.contact_1 .separate:before,
.contact_1 .separate:after {
  content: ' ';
  display: block;
  position: absolute;
  width: calc(100% / 2 - 60px);
  left: 0;
  top: 50%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.contact_1 .separate:after {
  left: auto;
  right: 0;
}

.contact_2 textarea.input {
  height: 170px;
}

/* 13. Showcases */
.showcase_1 .block a:hover {
  opacity: 0.8;
}
@media (max-width: 991.98px) {
  .showcase_1 .description {
    margin: 10px 0 0 0;
  }
}

.showcase_2 a:hover {
  opacity: 0.8;
}

/* 14. Footers */
@media (max-width: 575.98px) {
  .footer_1 .links a {
    margin: 0 10px;
  }
}

/* 15. Ecommerce */
.ecommerce_1 .product .img_link img {
  transition: all 0.25s;
}
.ecommerce_1 .product .img_link:hover img {
  opacity: 0.8;
}
.ecommerce_1 .product .btn {
  left: 0;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .ecommerce_1 .main_info .btn {
    margin-top: 30px;
  }
}

.ecommerce_2 .product {
  border: 2px solid #ebeaed;
}
.ecommerce_2 .product.big {
  min-height: calc(100% - 30px);
}
.ecommerce_2 .product.big ul {
  column-count: 2;
}
.ecommerce_2 .product li {
  list-style: none;
  position: relative;
}
.ecommerce_2 .product li:before {
  content: '';
  display: block;
  position: absolute;
  top: 11px;
  left: -10px;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #4c5a6b;
}
@media (max-width: 575.98px) {
  .ecommerce_2 .product {
    max-width: 290px;
    padding: 20px 20px 30px 20px;
  }
  .ecommerce_2 .product.big ul {
    column-count: 1;
  }
  .ecommerce_2 .product .left {
    margin-right: 0;
  }
  .ecommerce_2 .product img {
    width: 100%;
    max-width: 100%;
  }
}

/* 16. Applications */
@media (max-width: 767.98px) {
  .application_2 .application_2_left_img {
    max-height: 50vh;
  }
}
.application_2 .with_borders:nth-of-type(1) {
  border-bottom: 2px solid #ebeaed;
  border-right: 2px solid #ebeaed;
}
.application_2 .with_borders:nth-of-type(2) {
  border-bottom: 2px solid #ebeaed;
  border-right: 2px solid #ebeaed;
}
.application_2 .with_borders:nth-of-type(3) {
  border-bottom: 2px solid #ebeaed;
}
.application_2 .with_borders:nth-of-type(4) {
  border-right: 2px solid #ebeaed;
}
.application_2 .with_borders:nth-of-type(5) {
  border-right: 2px solid #ebeaed;
}
@media (max-width: 991.98px) {
  .application_2 .with_borders:nth-of-type(2) {
    border-right: none;
  }
  .application_2 .with_borders:nth-of-type(3) {
    border-right: 2px solid #ebeaed;
    padding-top: 35px;
  }
  .application_2 .with_borders:nth-of-type(4) {
    border-right: none;
    border-bottom: 2px solid #ebeaed;
  }
}

.division {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

@media (max-width: 1200px) {
  .header {
    height: 100vh;
  }
  .header .container {
    margin-top: 10vh;
  }
}
